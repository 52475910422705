<template>
    <div>
        <app-layout v-if="parameter">
            <template v-slot:header>
                <Header
                    :title="parameter.name"
                    :isNewButton="checkPermission('menuitem_store')"
                    @new-button-click="add"
                    @filter-div-status="datatable.filterStatus = $event"
                >
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="parameter.name"
                    :isNewButton="checkPermission('menuitem_store')"
                    @new-button-click="add"
                    @filter-div-status="datatable.filterStatus = $event"
                >
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
            >



                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('name')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.name"
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>


            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="modal" size="xl">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? "add" : "edit") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-6">
                                        <ValidationProvider
                                            name="permission_id"
                                            v-slot="{ errors }"
                                        >
                                            <b-form-group
                                                :label="$t('permission_id')"
                                            >
                                                <permission-selectbox
                                                    v-model="form.permission_id"
                                                >
                                                </permission-selectbox>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                >
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider
                                            name="url"
                                            v-slot="{ errors }"
                                        >
                                            <b-form-group :label="$t('url')">
                                                <b-form-input
                                                    type="url"
                                                    v-model="form.url"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                >
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider
                                            name="new_window"
                                            v-slot="{ errors }"
                                        >
                                            <b-form-group
                                                :label="$t('new_window')"
                                            >
                                                <true-false-selectbox
                                                    v-model="form.new_window"
                                                />
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-6">
                                        <ValidationProvider
                                            name="rank"
                                            v-slot="{ errors }"
                                        >
                                            <b-form-group :label="$t('rank')">
                                                <b-form-input
                                                    type="number"
                                                    step="1"
                                                    v-model="form.rank"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                >
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <b-form-group
                                        class="col-12"
                                        :label="$t('name')"
                                    >
                                        <ValidationProvider
                                            name="name"
                                            rules="required"
                                            v-slot="{ errors }"
                                        >
                                            <b-input-group
                                                class="mb-3"

                                            >
                                                <b-form-input
                                                    v-model="form.name"
                                                >
                                                </b-form-input>
                                            </b-input-group>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                                class="mb-2"
                                            >
                                            </b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </b-form-group>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button
                                @click="store"
                                type="button"
                                variant="primary"
                                class="btn-lg mr-2"
                            >
                                {{ $t("save") | toUpperCase }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import MenuService from "@/services/MenuService";
import CommonModal from "@/components/elements/CommonModal";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TrueFalseSelectbox from "@/components/interactive-fields/TrueFalseSelectbox";
import PermissionSelectbox from "@/components/interactive-fields/PermissionSelectbox";

export default {
    components: {
        PermissionSelectbox,
        TrueFalseSelectbox,
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.parameter
                ? this.parameter.name
                : "",
        };
    },
    data() {
        return {
            parameter: null,
            menu_id: 0,
            id: 0,
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "menuitem_update",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "menuitem_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: true,
                    },
                    {
                        label: this.$t("name"),
                        field: ("name"),
                        sortable: true,
                    },
                    {
                        label: this.$t("url"),
                        field: "url",
                        sortable: true,
                    },
                    {
                        label: this.$t("rank"),
                        field: "rank",
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            this.datatable.queryParams.filter.menu_id = this.menu_id;
            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };

            return MenuService.getAllItems(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    response;
                    this.datatable.total = response.data.data.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        loadData() {
            if (this.id > 0) {
                MenuService.getMenus(this.id)
                    .then((response) => {
                        this.$refs.storeForm.reset();
                        this.form = response.data.data;
                        this.$refs.modal.$refs.commonModal.show();
                    })
                    .catch((error) => {
                        if (error.data.message) {
                            this.$toast.error(
                                this.$t("api." + error.data.message)
                            );
                        }
                    });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate();
            this.form.menu_id = this.menu_id;
            if (isValid) {
                let formData = { ...this.form };
                this.setBooleans(formData);
                if (this.id == 0) {
                    MenuService.storeItem(formData)
                        .then((response) => {
                            this.$toast.success(
                                this.$t("api." + response.data.message)
                            );
                        })
                        .catch((error) => {
                            this.showErrors(error);
                        });
                } else {
                    MenuService.updateItem(this.id, formData)
                        .then((response) => {
                            this.$toast.success(
                                this.$t("api." + response.data.message)
                            );
                        })
                        .catch((error) => {
                            this.showErrors(error);
                        });
                }
            }
        },
        showErrors(error) {
            if (error.status == 422) {
                if (error.data.errors.name) {
                    this.$refs.storeForm.errors.name.push(
                        error.data.errors.name[0]
                    );
                }
                if (error.data.errors.rank) {
                    this.$refs.storeForm.errors.rank.push(
                        error.data.errors.rank[0]
                    );
                }

                if (error.data.errors.permission_id) {
                    this.$refs.storeForm.errors.permission_id.push(
                        error.data.errors.permission_id[0]
                    );
                }
                if (error.data.errors.menu_id) {
                    this.$refs.storeForm.errors.menu_id.push(
                        error.data.errors.menu_id[0]
                    );
                }
                if (error.data.errors.url) {
                    this.$refs.storeForm.errors.url.push(
                        error.data.errors.url[0]
                    );
                }
                if (error.data.errors.new_window) {
                    this.$refs.storeForm.errors.new_window.push(
                        error.data.errors.new_window[0]
                    );
                }
            } else if (error.status == 406) {
                this.$toast.error(this.$t("api." + error.data.message));
            }
        },
        delete(id) {
            this.deleteModal(() => {
                MenuService.deleteMenuItem(id)
                    .then((response) => {
                        this.filter();
                        this.$toast.success(
                            this.$t("api." + response.data.message)
                        );
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t("api." + error.data.message));
                    });
            });
        },
        add() {
            this.id = 0;
            this.$refs.storeForm.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        },
    },
    created() {
        this.menu_id = this.$route.params.id;

        if (this.menu_id) {
            MenuService.getMenu(this.menu_id).then((response) => {
                this.parameter = response.data.data;
                this.menu_id = response.data.data.id;
                this.getRows();
            });
        } else {
            this.$router.push("404");
        }
    },
};
</script>
